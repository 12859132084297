import { extend, localize } from "vee-validate";
import {
  required as ruleRequired,
  email as ruleEmail,
  min as ruleMin,
  confirmed as ruleConfirmed,
  regex as ruleRegex,
  between as ruleBetween,
  alpha as ruleAlpha,
  integer as ruleInteger,
  digits as ruleDigits,
  alpha_dash as ruleAlphaDash,
  alpha_num as ruleAlphaNum,
  length as ruleLength,
} from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";
import es from "vee-validate/dist/locale/es.json";
import ca from "vee-validate/dist/locale/ca.json";

// eslint-disable-next-line object-curly-newline
import {
  validatorPositive,
  validatorUrlValidator,
  validatorPassword,
  validatorConfirmPassword,
  validatorCreditCard,
  validatorCheckinTime,
  validatorCheckoutTime,
  validatorPhone,
  validatorPolicy,
} from "./validators";

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend("required", ruleRequired);

export const email = extend("email", ruleEmail);

export const min = extend("min", ruleMin);

export const confirmed = extend("confirmed", ruleConfirmed);

export const regex = extend("regex", ruleRegex);

export const between = extend("between", ruleBetween);

export const alpha = extend("alpha", ruleAlpha);

export const integer = extend("integer", ruleInteger);

export const digits = extend("digits", ruleDigits);

export const alphaDash = extend("alpha-dash", ruleAlphaDash);

export const alphaNum = extend("alpha-num", ruleAlphaNum);

export const length = extend("length", ruleLength);

export const positive = extend("positive", {
  validate: validatorPositive,
  message: "Please enter positive number!",
});

export const credit = extend("credit-card", {
  validate: validatorCreditCard,
  message: "It is not valid credit card!",
});

export const password = extend("password", validatorPassword);

export const confirmpassword = extend(
  "confirmpassword",
  validatorConfirmPassword
);

export const url = extend("url", {
  validate: validatorUrlValidator,
  message: "URL is invalid",
});

export const checkin = extend("checkin", validatorCheckinTime);

export const checkout = extend("checkout", validatorCheckoutTime);

export const phone = extend("phone", validatorPhone);

export const policy = extend("policy", validatorPolicy);

// Install English, Spanish & Catalan localizations.
localize({
  en: {
    messages: en.messages,
    names: {
      accommodationArrivalTime: "Accommodation arrival time",
      accommodationDepartureTime: "Accommodation departure time",
      arrivalFlightNumber: "Arrival flight nº",
      departureFlightNumber: "Departure flight nº",
      email: "Email",
      password: "Password",
      newPassword: "New password",
      confirmPassword: "Confirm password",
      phone: "Phone number",
      policy: "Terms & Conditions",
    },
    fields: {
      accommodationArrivalTime: {
        checkin: "The check-in time must be from 16h",
      },
      accommodationDepartureTime: {
        checkout: "The check out time can not be later than 10h",
      },
      currentPassword: {
        required: "The Current password field is required",
        password:
          "Password must be at least 6 characters with one uppercase, one lowercase and one number",
      },
      newPassword: {
        password:
          "Password must be at least 6 characters with one uppercase, one lowercase and one number",
      },
      confirmPassword: {
        confirmpassword: "The passwords do not match",
      },
      phone: {
        phone: "The phone number is not valid",
      },
      policy: {
        policy: "You must accept the Privacy Policy and the Terms & Conditions",
      },
    },
  },
  es: {
    messages: es.messages,
    names: {
      accommodationArrivalTime: "Hora de llegada al alojamiento",
      accommodationDepartureTime: "Hora de salida del alojamiento",
      arrivalFlightNumber: "Nº de vuelo de llegada",
      departureFlightNumber: "Nº de vuelo de salida",
      email: "Correo electrónico",
      password: "Contraseña",
      newPassword: "Nueva contraseña",
      confirmPassword: "Confirma contraseña",
      phone: "Nº teléfono",
      policy: "Términos y Condiciones",
    },
    fields: {
      accommodationArrivalTime: {
        checkin: "La hora de entrada debe ser a partir de las 16h",
      },
      accommodationDepartureTime: {
        checkout: "La hora de salida no puede ser posterior a las 10h",
      },
      currentPassword: {
        required: "El campo Contraseña actual es obligatorio",
        password:
          "La contraseña debe tener al menos 6 caracteres con una mayúscula, una minúscula y un número",
      },
      newPassword: {
        password:
          "La contraseña debe tener al menos 6 caracteres una mayúscula, una minúscula y un número",
      },
      confirmPassword: {
        confirmpassword: "Las contraseñas no coinciden",
      },
      phone: {
        phone: "El nº de teléfono no es válido",
      },
      policy: {
        policy:
          "Debes aceptar la Política de Privacidad y los Términos y Condiciones",
      },
    },
  },
  ca: {
    messages: ca.messages,
    names: {
      accommodationArrivalTime: "Hora d'arribada a l'allotjament",
      accommodationDepartureTime: "Hora de sortida de l'allotjament",
      arrivalFlightNumber: "Nº de vol d'arribada",
      departureFlightNumber: "Nº de vol de partida",
      email: "Correu electrònic",
      password: "Clau d'accés",
      newPassword: "Nova clau d'accés",
      confirmPassword: "Confirma clau d'accés",
      phone: "Nº telèfon",
      policy: "Termes i Condicions",
    },
    fields: {
      accommodationArrivalTime: {
        checkin: "L'hora d'entrada ha de ser a partir de les 16h",
      },
      accommodationDepartureTime: {
        checkout: "L'hora de sortida no pot ser posterior a les 10h",
      },
      currentPassword: {
        required: "El camp Clau d'accés actual és obligatori",
        password:
          "La clau d'accés ha de tenir almenys 6 caràcters amb una majúscula, una minúscula i un número",
      },
      newPassword: {
        password:
          "La clau d'accés ha de tenir almenys 6 caràcters amb una majúscula, una minúscula i un número",
      },
      confirmPassword: {
        confirmpassword: "Les claus d'accés no coincideixen",
      },
      phone: {
        phone: "El nº de telèfon no és vàlid",
      },
      policy: {
        policy:
          "És necessari acceptar la Política de Privacitat i els Termes i Condicions",
      },
    },
  },
});
